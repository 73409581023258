'use client';

import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { usePathname, useSearchParams } from 'next/navigation';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { formatPathToReadableEventName } from '@/shared/utils';
import { MixpanelPageContext } from './types';

const Context = React.createContext<MixpanelPageContext>({ source: '', utmString: '' });

export const usePageContext = () => useContext(Context);

export function PageProvider(props: PropsWithChildren) {
  const { children } = props;
  const [utmString, setUtmString] = useState('');
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { sendSafeMixpanelEvent } = useAnalyticsContext()
  const source = formatPathToReadableEventName(pathname);

  useEffect(() => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
      debug: process.env.NEXT_PUBLIC_RUNTIME_ENV !== 'development',
      persistence: 'localStorage',
    })
  }, [])

  useEffect(() => {
    const searchParamsString = searchParams.toString();

    if (searchParamsString.length > 0) {
      setUtmString(
        searchParamsString
          .split('&')
          .filter((param) => param.includes('utm_'))
          .join('&')
      );
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sendSafeMixpanelEvent('track_pageview', { source })
    }
  }, [source, sendSafeMixpanelEvent]);

  const value = React.useMemo(() => ({ source, utmString }), [source, utmString]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
