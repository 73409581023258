'use client';

import React, { useCallback } from 'react';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { usePageContext } from '@/context/PageContextProvider';

function useOutsideClick(elementRef: React.RefObject<any>, onOutsideClick: (event: any) => void) {
  React.useEffect(() => {
    function handleClickOutside(event: Event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onOutsideClick(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef, onOutsideClick]);
}

function useModalControls(initState = false, options?: { disableBodyScroll?: boolean }) {
  const [isOpen, setIsOpen] = React.useState(initState);

  const openModal = React.useCallback(() => setIsOpen(true), []);
  const closeModal = React.useCallback(() => setIsOpen(false), []);

  React.useEffect(() => {
    if (options?.disableBodyScroll) {
      const escapeKeyListener = (e: KeyboardEvent) => e.code === 'Escape' && closeModal();

      if (isOpen) {
        window.addEventListener('keydown', escapeKeyListener);
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
      } else {
        window.removeEventListener('keydown', escapeKeyListener);
        document.body.style.overflow = '';
        document.documentElement.style.overflow = '';
      }
    }
  }, [closeModal, isOpen, options?.disableBodyScroll]);

  return {
    isOpen,
    openModal,
    closeModal,
  };
}

function useSignUp(eventName: string, payload: { type: string }) {
  const { source, utmString } = usePageContext();
  const { sendSafeFbqEvent, sendSafeGtagEvent } = useAnalyticsContext();
  const utmQuery = `?get_started_type=${payload.type}${utmString.length > 0 ? `&${utmString}` : ''}`;

  const signUpClick = useCallback(() => {
    sendSafeGtagEvent(eventName, { source })
    sendSafeFbqEvent('Lead')
  }, [sendSafeGtagEvent, sendSafeFbqEvent, eventName, source]);

  return { signUpClick, signUpLink: `${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/auth/register${utmQuery}` }
}

export { useModalControls, useOutsideClick, useSignUp };
